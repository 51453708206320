/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 15, 2018 */



@font-face {
    font-family: 'loveloline_light';
    src: url('lovelo_line_light-webfont.woff2') format('woff2'),
         url('lovelo_line_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}