/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 2, 2018 */



@font-face {
    font-family: 'proxima_novaregular';
    src: url('proximanova-regular-webfont.woff2') format('woff2'),
         url('proximanova-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}