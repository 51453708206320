/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 2, 2018 */



@font-face {
    font-family: 'brandon_grotesquebold';
    src: url('brandon_bld-webfont.woff2') format('woff2'),
         url('brandon_bld-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}