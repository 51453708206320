@charset "utf-8";

#polyglotLanguageSwitcher, #polyglotLanguageSwitcher * {
	margin: 0;
	padding: 0; 
	outline: none;
}

#polyglotLanguageSwitcher ul {
	list-style: none;	
}

#polyglotLanguageSwitcher {
	color: #fff;
	line-height: normal;
	position: relative; /* sets the initial position for the drop-down menu */	
}

#polyglotLanguageSwitcher form {
	display: none;	
}

/* ---------------------------------------------------------------------- */
/* JS-created Code
/* ---------------------------------------------------------------------- */
#polyglotLanguageSwitcher a {
	text-decoration: none;
    display: block;
    padding: 5px 0px 5px 32px;
    background-repeat: no-repeat;
    background-position: 1px center;
}


#polyglotLanguageSwitcher a.current:link, #polyglotLanguageSwitcher a.current:visited, #polyglotLanguageSwitcher a.current:active {
	position: relative; /* sets the initial position for the trigger arrow */
}



#polyglotLanguageSwitcher a.active { /* This style is applied as long as the drop-down menu is visible. */
	border-bottom: none !important;
	border-radius: 3px 3px 0 0 !important;
}

#polyglotLanguageSwitcher span.trigger {
	display: block;
	position: absolute;
	top: 0;
    right: 0;
}


#polyglotLanguageSwitcher a.current:hover span.trigger, #polyglotLanguageSwitcher a.current:active span.trigger, #polyglotLanguageSwitcher a.active span.trigger {
	background-position: left bottom !important;
}
/* Drop-Down Menu */

#polyglotLanguageSwitcher ul.dropdown {
	box-shadow: 0px 0px 22.5px 7.5px rgba(216, 225, 229, 0.3);
	background: #fff;
	display: none;
	position: absolute;
	top:calc(100% + 2px);
	right: 0;
	border-top: none !important;
	border-radius: 5px;
	padding: 0;
	z-index: 99999999999999999999999;
}

#polyglotLanguageSwitcher ul.dropdown li {
	width:100px;
	padding: 0 10px;
	border-top: 1px solid rgba(0,0,0,0.05);
	line-height: 30px;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
#polyglotLanguageSwitcher ul.dropdown li a {
	font-size: 14px;
	width:100%;
}
#polyglotLanguageSwitcher ul.dropdown li:first-child {
	border:none;
}
#polyglotLanguageSwitcher ul.dropdown li:last-child { 
	border-radius: 0 0 3px 3px;
}
/* Flags */

#en { 
	background-image: url(../../images/home/gb.png);
}

#fr { 
	background-image: url(../../images/home/fr.png);	
}

#de { 
	background-image: url(../../images/home/de.png);	
}

#it { 
	background-image: url(../../images/home/it.png);	
}

#es { 
	background-image: url(../../images/home/es.png);	
}